import React from 'react';
import {withRouter} from "react-router-dom";
import SingleProposal from "./SingleProposal";
import {Paper, styled} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Stack from '@mui/material/Stack';
import {Pagination} from "@material-ui/lab";
import useFetch from "./UseFetch";
import {ReactSession} from "react-client-session";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

class Proposals extends React.Component {

    api = undefined;

    constructor(props) {
        super(props);
        this.state = {
            states: [],
            pagination: 0,
            selectedProposal: 0
        };
    }

    componentDidMount() {
        this.api = useFetch();
        this.initialData();
    }

    async initialData() {


        console.log(ReactSession.get('token'))

        let url = 'https://api.nicer-pricer.de' + this.props.initialDataUrl
        let {response, data} = await this.api(url);

        console.log(response);
        console.log(data);

        console.log(data);
        console.log(data.data.length);

        let map = [];
        for (const [key, value] of Object.entries(data['data'])) {

            let number = parseInt(key) + 1;
            map[number] = value.id;
        }

        console.log(map);
        this.setState({
            pagination: data.data.length,
            map: map,
            selectedProposal: map[1]
        }, () => {
        });
    }

    onChange = (e, value) => {
        console.log(value);
        this.setState({
            selectedProposal: this.state.map[value]
        });
    }

    render() {

        return (
            <React.Fragment>
                <Grid>
                    {this.state.selectedProposal !== undefined ? <div style={{padding: '40px', textAlign: 'center', justifyContent:"center", display:'flex'}}>
                        <Stack spacing={2} style={{ justifyContent:"center", display:'flex'}}>
                            <Pagination count={this.state.pagination} onChange={this.onChange}/>
                        </Stack>
                    </div> : ''}

                    <Box sx={{p: 2}}>
                        {this.state.selectedProposal !== undefined && this.state.selectedProposal !== 0 ?
                        <SingleProposal dataProvider={this.props.dataProvider} key={this.state.selectedProposal}
                                        initialDataUrl={"/proposal/" + this.state.selectedProposal + "?include=files"}
                        /> : <p>Keine Proposals vorhanden</p>}
                    </Box>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withRouter(Proposals);

import React from 'react';
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Card, CircularProgress, createTheme, styled, TableCell} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import base64 from 'base-64'
import useFetch from "./UseFetch";


var button6Sperre = false;
var button7Sperre = false;
var button12Sperre = false;
var button13Sperre = false;

function createData(name, calories, fat, carbs, protein) {
    return {name, calories, fat, carbs, protein};
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

class SingleProposal extends React.Component {

    api = undefined;

    constructor(props) {
        super(props);
        this.state = {
            states: [],
            test: '',
            selectedRow: 0,
            maxRow: 0
        };
    }

    componentDidMount() {
        window.addEventListener("gamepadconnected", this.connect);

        this.api = useFetch();
        this.initialData();
    }

    async initialData() {

        let url = 'https://api.nicer-pricer.de' + this.props.initialDataUrl
        let {response, data} = await this.api(url);

        console.log(response);
        console.log(data);

        console.log("initialData");
        console.log(data);

        let proposalFiles = [];
        let productFiles = [];

        if (data['included'] != undefined) {
            for (const [key, value] of Object.entries(data['included'])) {
                let file = {};
                file['id'] = value.id;
                if (value.attributes['objectType'] !== undefined) {
                    file['objectType'] = value.attributes['objectType'];
                }

                proposalFiles.push(file)
            }
        }
        let attributesProduct = [];
        console.log(data['data']['attributes']['ean']);
        let headers = new Headers();

        url = 'https://api.nicer-pricer.de/products?include=files&filter%5Bean%5D=' + encodeURIComponent(data['data']['attributes']['ean']);
        let temp = await this.api(url);

        console.log(temp.data['data'])
        if (temp.data['data'] && temp.data['data'][0] !== undefined) {
            for (const [key1, value1] of Object.entries(temp.data['data'][0]['attributes'])) {
                attributesProduct[key1] = value1;
            }
            if (temp.data['included'] !== undefined) {
                for (const [key, value] of Object.entries(temp.data['included'])) {
                    let file = {};
                    file['id'] = value.id;
                    if (value.attributes['objectType'] !== undefined) {
                        file['objectType'] = value.attributes['objectType'];
                    }

                    productFiles.push(file)
                }
            }
        }




        let i = 0;
        for (const [key, value] of Object.entries(data['data']['attributes'])) {
            if (key != 'status' && key != 'creator_id' && key != 'createdAt' && key != 'updatedAt' && key != 'filesCount' && key != 'commentsCount') {
                i++;
            }
        }
        for (const [key, value] of Object.entries(proposalFiles)) {
            i++;
        }


        console.log(typeof attributesProduct);
        console.log(attributesProduct);
        console.log(attributesProduct.length);

        this.setState({
            proposalid: data['data']['id'],
            attributesProduct: attributesProduct,
            attributesProposal: data['data']['attributes'],
            proposalFiles: proposalFiles,
            productFiles: productFiles,
            maxRow: i,
            updateAttributes: {}
        }, () => {
        });
    }


    connect = (evt) => {
        this.updateTimer = setInterval(() => {
            var gamepads = navigator.getGamepads();

            var pressed6 = gamepads[0]['buttons'][6].pressed;
            var pressed7 = gamepads[0]['buttons'][7].pressed;
            var pressed12 = gamepads[0]['buttons'][12].pressed;
            var pressed13 = gamepads[0]['buttons'][13].pressed;

            var buttons = gamepads[0]['buttons'].map(function (button, index) {
                if (button.pressed) {
                    console.log(index);
                }
            });

            if (gamepads[0]['buttons'][6].pressed) {
                this.button6pressed();
                button6Sperre = true;
            } else {
                button6Sperre = false;
            }
            if (gamepads[0]['buttons'][7].pressed) {
                this.button7pressed();
                button7Sperre = true;
            } else {
                button7Sperre = false;
            }
            if (gamepads[0]['buttons'][12].pressed) {
                this.button12pressed();
                button12Sperre = true;
            } else {
                button12Sperre = false;
            }
            if (gamepads[0]['buttons'][13].pressed) {
                this.button13pressed();
                button13Sperre = true;
            } else {
                button13Sperre = false;
            }


        }, 10);
        console.log('Gamepad connected.');
    }


    button6pressed = () => {
        if (!button6Sperre) {
            let selectedRow = this.state.selectedRow;
            if (selectedRow < 6) {

                let row = document.getElementById(selectedRow);

                let cell = row.getElementsByTagName('td')
                cell[1].style.backgroundColor = "red";

                this.setState({
                    selectedRow: ++this.state.selectedRow
                }, () => {
                    try {
                        let row = document.getElementById(this.state.selectedRow);

                        row.scrollIntoView({block: "end", behavior: "smooth"});

                    } catch (e) {

                    }
                })
            }
        }
    }
    button7pressed = () => {
        if (!button7Sperre) {
            let selectedRow = this.state.selectedRow;
            console.log(selectedRow);
            if (selectedRow < 6) {
                let row = document.getElementById(selectedRow);

                let cell = row.getElementsByTagName('td')
                cell[1].style.backgroundColor = "green";


                var updateAttributes = this.state.updateAttributes;
                if (cell[1].textContent == '') {

                    let split = cell[1].id.split("-");
                    if (updateAttributes["files"] == undefined) {
                        updateAttributes["files"] = {[split[0]]: split[1]};
                    } else {
                        updateAttributes["files"].push({[split[0]]: split[1]})
                    }


                } else {
                    updateAttributes[cell[1].id] = cell[1].textContent;
                }
                this.setState({updateAttributes});


                this.setState({
                    updateAttributes: updateAttributes,
                    selectedRow: ++this.state.selectedRow
                }, () => {
                    try {
                        let row = document.getElementById(this.state.selectedRow);

                        row.scrollIntoView({block: "end", behavior: "smooth"});

                    } catch (e) {

                    }
                })
            }
        }
    }


    button12pressed = () => {
        if (!button12Sperre) {
            let newRow = --this.state.selectedRow;

            var element = document.getElementById(newRow);

            try {
                element.scrollIntoView({block: "end", behavior: "smooth"});

            } catch (e) {

            }

            this.setState({
                selectedRow: newRow
            })
        }
    }
    button13pressed = () => {
        if (!button13Sperre) {
            let newRow = ++this.state.selectedRow;

            var element = document.getElementById(newRow);

            try {
                element.scrollIntoView({block: "end", behavior: "smooth"});

            } catch (e) {

            }

            this.setState({
                selectedRow: newRow
            })
        }
    }

    delete = async (e) => {


        let url = "https://api.nicer-pricer.de/proposal/" + this.state.proposalid;
        let {response, data} = await this.api(url, 'DELETE');

        if (response) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            window.location.reload(false)
        }
    }

    update = async (e) => {
        const body = new FormData;

        console.log(this.state.updateAttributes);

        let updatedAttributes = {
            data: this.state.updateAttributes,
            files: this.state.updateAttributes["files"]
        }

        let url = "https://api.nicer-pricer.de/product/update-from-proposal/" + this.state.proposalid;
        let {response, data} = await this.api(url, 'POST', JSON.stringify(updatedAttributes));

        console.log(response);
        console.log(data);

        if (response) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            window.location.reload(false)
        }
    }

    updateFull = async (e) => {
        console.log("updateFull")
        const body = new FormData;

        let updatedAttributes = {
            files: []
        }


        let url = "https://api.nicer-pricer.de/product/update-from-proposal/" + this.state.proposalid;
        let {response, data} = await this.api(url, 'POST', JSON.stringify(updatedAttributes));

        console.log(response);
        console.log(data);

        if (response) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            window.location.reload(false)
        }
    }

    addValue = (e, onlyColor = false) => {
        console.log(e.target);
        console.log(onlyColor);
        if (e.target.textContent != '')


            if (!onlyColor) {
                let updateAttributes = this.state.updateAttributes;
                if (updateAttributes[e.target.id] == undefined) {
                    updateAttributes[e.target.id] = e.target.textContent;
                    e.target.style.backgroundColor = "green";
                } else {
                    let temp = [];
                    for (const [key, value] of Object.entries(updateAttributes)) {
                        if (key != e.target.id) {
                            temp[key] = value;
                        }
                    }
                    //updateAttributes[e.target.id] = undefined;
                    updateAttributes = temp;
                    e.target.style.backgroundColor = "inherit";
                }


                this.setState({updateAttributes}, () => {
                    console.log(this.state.updateAttributes);
                });
            }
    }

    addValuePhoto = (e, onlyColor = false) => {
        console.log(e.target);
        console.log(onlyColor);
        let test = e.target;
        if (test.id == undefined || test.id == '') {
            test = e.target.parentElement;
        }
        console.log(test);
        var updateAttributes = this.state.updateAttributes;
        let split = test.id.split("-");

        if (test.getElementsByTagName('img') != undefined && test.getElementsByTagName('img')[0] != undefined && split[0] != undefined && split[1] != undefined) {
            test.style.backgroundColor = "green";
        }
        if (!onlyColor && test.textContent == '') {
            console.log(updateAttributes["files"])

            if (split[1] != undefined) {
                console.log(updateAttributes["files"]);
                if (updateAttributes["files"] == undefined) {
                    console.log('1');
                    updateAttributes["files"] = {[split[0]]: {id: split[1]}};
                    test.style.backgroundColor = "green"
                } else {
                    if (updateAttributes["files"][split[0]] != undefined && updateAttributes["files"][split[0]]['id'] != undefined) {
                        console.log('2');
                        test.style.backgroundColor = "inherit"
                        let temp = [];
                        for (const [key, value] of Object.entries(updateAttributes['files'])) {
                            if (key != split[0]) {
                                temp[key] = value;
                            }
                        }
                        updateAttributes['files'] = temp;
                    } else {
                        console.log('3');
                        test.style.backgroundColor = "green"
                        updateAttributes["files"][split[0]] = {['id']: split[1]};
                    }
                }
                this.setState({updateAttributes}, () => {
                    console.log(this.state.updateAttributes);
                });
            }
        }
    }

    getAttributes = () => {
        let test = []
        let i = 0;
        for (const [key, value] of Object.entries(this.state.attributesProposal)) {
            if (key != 'ean' && key != 'status' && key != 'creator_id' && key != 'createdAt' && key != 'updatedAt' && key != 'filesCount' && key != 'commentsCount') {
                test.push(
                    <TableRow
                        selected={this.state.selectedRow === i} id={i}
                        key={'attribute-' + i}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="right" onClick={(e) => this.addValue(e, true)}
                                   style={{fontSize: '20px'}}>{this.state.attributesProduct[key]}</TableCell>
                        <TableCell component="th" scope="row"
                                   style={{fontSize: '20px', textAlign: 'center', fontWeight: 'bold'}}>
                            {key}
                        </TableCell>
                        <TableCell align="left" id={key} onClick={(e) => this.addValue(e)}
                                   style={{fontSize: '20px'}}>{value}</TableCell>
                    </TableRow>
                );
                i++;
            }
        }
        let categories = ['Front', 'Back', 'Ingredients']
        console.log(this.state.productFiles);
        console.log(this.state.proposalFiles);
        for (let i = 0; i < 3; i++) {
            let proposalFile = this.state.proposalFiles.filter(v => v.objectType == categories[i]);
            if (proposalFile) proposalFile = proposalFile[0];
            console.log(proposalFile);
            let productFile;
            for (const [key1, value1] of Object.entries(this.state.productFiles)) {
                console.log(value1.objectType)
                console.log(categories)
                console.log(value1.objectType == categories[i])
                if ((proposalFile != undefined && value1.objectType == proposalFile.objectType) || (value1.objectType == categories[i] && this.state.productFiles[key1] != undefined)) {
                    productFile = this.state.productFiles[key1];
                    break;
                }
            }
            console.log(productFile);

            //if (!(productFile == undefined && proposalFile == undefined)) {
            test.push(<TableRow classes={{hover: "hover"}} selected={this.state.selectedRow === i} id={i}
                                key={'file-' + i}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell align="right" id={productFile != undefined ? productFile.objectType + "-" : ''}
                           onClick={(e) => this.addValuePhoto(e, true)}
                           style={{
                               maxWidth: '40%',
                               width: '40%'
                           }}>{productFile != undefined ? this.getFile(productFile.objectType, productFile.id) : ''}</TableCell>
                <TableCell component="th" scope="row"
                           style={{fontSize: '20px', textAlign: 'center', fontWeight: 'bold'}}>
                    {categories[i]}
                </TableCell>
                <TableCell align="center"
                           id={proposalFile != undefined ? proposalFile.objectType + "-" + proposalFile.id : ''}
                           onClick={(e) => this.addValuePhoto(e)}
                           style={{
                               margin: 'auto',
                               maxWidth: '40%',
                               width: '40%'
                           }}>{proposalFile != undefined ? this.getFile(proposalFile.objectType, proposalFile.id) : ''}</TableCell>
            </TableRow>)
            //}
        }
        return test;
    }
    /*<div style={{textAlign: 'center'}}><CircularProgress /></div>*/
    getFile = (objectType, id) => {
        return <img className={objectType + "-" + id} src={'https://api.nicer-pricer.de/file/' + id + '/download/content'}
                    width={'100%'} alt={'asd'}/>
    }

    getButtons = () => {
        return (<TableRow classes={{hover: "hover"}} id={this.state.selectedRow}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell align="center" style={{maxWidth: '40%', width: '40%'}}><Button onClick={this.delete}>Proposal
                verwerfen</Button></TableCell>
            <TableCell component="th" scope="row" style={{fontSize: '20px', textAlign: 'center', fontWeight: 'bold'}}>
                <Button onClick={this.update}
                        disabled={this.state.updateAttributes && Object.keys(this.state.updateAttributes).length === 0}>Änderungen
                    übernehmen</Button>
            </TableCell>
            <TableCell align="center"
                       style={{maxWidth: '40%', width: '40%'}}><Button onClick={this.updateFull}>Proposal komplett
                übernehmen</Button></TableCell>
        </TableRow>);
    }

    render() {

        const lightTheme = createTheme({palette: {mode: 'light'}});

        return (
            <React.Fragment>
                <div style={{textAlign: 'left'}}>
                    {this.state.proposalid !== undefined && this.state.proposalid !== '' ?
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    p: 5,
                                }}
                            >
                                    <TableContainer component={Paper} style={{paddingBottom: '0'}}>
                                        <Table sx={{minWidth: 650}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right"
                                                               style={{fontSize: '20px'}}>Product</TableCell>
                                                    <TableCell align="center" style={{
                                                        fontSize: '20px',
                                                        fontWeight: 'bold'
                                                    }}>EAN: {this.state.attributesProduct.ean ? this.state.attributesProduct.ean : this.state.attributesProposal.ean}</TableCell>
                                                    <TableCell align="left"
                                                               style={{fontSize: '20px'}}>Proposal</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.getAttributes()}
                                                {this.getButtons()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                            </Box>
                        </Grid>
                        : <div style={{textAlign: 'center'}}><CircularProgress/></div>}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(SingleProposal);
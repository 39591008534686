import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SingleProposal from "./SingleProposal";
import Proposals from "./Proposals";
import {ReactSession} from "react-client-session";
import {withRouter} from "react-router-dom";

class Home extends React.Component {

    componentDidMount() {
        if (!ReactSession.get('token')) {
            this.props.history.push('/login')
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!ReactSession.get('token')) {
            this.props.history.push('/login')
        }
    }

    render() {
      return (
          <>
              <Proposals dataProvider={this.props.api}
                         initialDataUrl="/proposals" />
          </>
      );
    }
}
export default withRouter(Home);
